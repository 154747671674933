/**
 * Converts an array of elements to an entities collection
 *
 * @param { T[] } arr
 * @returns {  Entity<T> }
 */

import type { Entity, GenericType } from '@revolutionprep/types'

export default function<T> (array: T[], byProp?: string): Entity<T> {
  return array.reduce(
    (allEntities: Entity<T>, item: T) => ({
      ...allEntities,
      [byProp ? ((item as GenericType)[byProp] as string | number) : (item as GenericType).id]: item
    }),
    {}
  )
}
